import React, { useState, useEffect, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import localForage from 'localforage';
import cn from 'classnames';
import { capitalize } from 'lodash';
// Components
import { push as Menu } from 'react-burger-menu';
import Button from 'shared/buttons/Button';
import { HiOutlineLogout } from 'react-icons/hi';
import { BsExclamationTriangle } from 'react-icons/bs';
import gwiLogo from 'src/../public/images/gwiilogo.png';
// Hooks
import { useQueryClient, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import useNewDispatchesStatus from './useNewDispatchesStatus';
import { useMessagingContext } from '@gulfwinds/ui-components';
import useMessagingConnectionStatus from 'shared/../hooks/useMessagingConnectionStatus';
// Redux
import { setUser } from 'src/redux/reducers/user';
// Fetch
import { getSyncIntervals } from 'src/fetch/drivers';
import { logoutDriver } from 'src/fetch/client';
// Consts
import { MOVE_DRIVER_COMPLIANCE, MOVE_FUEL_RATES_V2 } from 'src/shared/constants/featureFlags';

const useSyncIntervals = () => useQuery(['syncIntervals'], () => getSyncIntervals());

const LogoutButton = ({ logOut }) => (
  <Button
    id='logout-button'
    mode='flat'
    onClick={logOut}
    icon={<HiOutlineLogout className='text-action-secondary-20 text-xl' />}
  />
);

const NavLink = ({ isActive, ...props }) => {
  const linkClassName = cn('flex text-sm font-semibold p-2 pr-3', {
    'text-white bg-primary-80': isActive,
    'text-text-10': !isActive,
  });
  return <Link className={linkClassName} {...props} />;
};

const MessagingLink = (props) => {
  const { hasNewMessages } = useMessagingContext();
  const { isMessagingDisconnected } = useMessagingConnectionStatus();
  return (
    <NavLink to='/messaging' {...props}>
      <i className='fas fa-comments fa-fw mr-3 text-xl ml-1' />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        Messaging
        {hasNewMessages > 0 && <i className='fas fa-comment-dots' />}
        {isMessagingDisconnected && (
          <div className='flex items-center'>
            <span className='mr-1'>Not Connected</span>
            <BsExclamationTriangle />
          </div>
        )}
      </div>
    </NavLink>
  );
};

const SideMenu = ({ pageWrapId, outerContainerId }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const storedUser = useSelector((state) => state.user);
  const { featureFlags = {} } = storedUser;

  const dispatchAction = useDispatch();

  const [collapsed, setCollapsed] = useState(true);
  const handleCollapse = () => setCollapsed(true);
  const handleOpen = () => setCollapsed(false);

  const { hasNewDispatches, newDispatchesCount } = useNewDispatchesStatus();

  const { data: syncIntervals } = useSyncIntervals();
  const [driver, setDriver] = useState(null);
  const [driverPhoto, setDriverPhoto] = useState(null);

  const queryClient = useQueryClient();

  const logOut = () => {
    dispatchAction(
      setUser({
        loginId: '',
        pinNumber: '',
        name: '',
        authenticated: false,
        acceptedTerms: false,
      }),
    );
    localForage.setItem('gwiMove_user_info', null);
    setDriver(null);
    setDriverPhoto(null);

    // Clear auth info
    localStorage.removeItem('gwisession');
    localStorage.removeItem('auth-token');
    localStorage.removeItem('refresh-token');

    // fire and forget
    logoutDriver({
      driverNumber: localStorage.getItem('driverNumber'),
      reason: 'Manual Logout',
    }).catch((error) => { console.error(error); });

    history.push('/');
    queryClient.clear();
  };

  const load = async () => {
    try {
      const driver = await localForage.getItem('gwiMove_user_info');
      setDriver(driver);
      let driverPhoto = '';
      if (
        driver.photo.thumbnailUrl !== undefined
        && driver.photo.thumbnailUrl !== null
        && driver.photo.thumbnailUrl !== ''
      ) {
        driverPhoto = driver.photo.thumbnailUrl;
      } else {
        driverPhoto = driver.photo.fullSizeUrl;
      }
      setDriverPhoto(driverPhoto);
    } catch {
      console.error('Could not set user.');
    }
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (syncIntervals && syncIntervals.conversations > 0) {
      queryClient.setQueryDefaults(['messaginginfo'], { refetchInterval: syncIntervals.conversations * 1000 });
    }
  }, [syncIntervals]);

  const DefaultNavLink = useMemo(() => ({ pathName, ...props }) => (
    <NavLink
      to={pathName}
      isActive={pathname.startsWith(pathName)}
      onClick={handleCollapse}
      {...props}
    />
  ), [pathname]);

  return (
    <Menu
      isOpen={!collapsed}
      onOpen={handleOpen}
      onClose={handleCollapse}
      outerContainerId={outerContainerId}
      pageWrapId={pageWrapId}
      className='bg-primary-100'
    >
      <div className='bg-primary-100 flex-1 flex flex-col text-md'>
        <div className='flex items-center p-4' style={{ paddingTop: '1.25rem' }}>
          <img src={gwiLogo} style={{ width: '125px' }} alt='gwii-logo' />
        </div>
        <nav className='flex-1 flex flex-col'>
          <DefaultNavLink pathName='/dispatching'>
            <i className='fas fa-truck-container fa-fw mr-3 text-xl ml-1' />
            Dispatches
            {hasNewDispatches && (
              <div className='rounded bg-red-600 text-white text-center py-1 px-2 ml-32'>
                {newDispatchesCount}
              </div>
            )}
          </DefaultNavLink>
          <MessagingLink isActive={pathname.startsWith('/messaging')} onClick={handleCollapse} />
          <DefaultNavLink pathName='/repair-orders'>
            <i className='fas fa-tools fa-fw mr-3 text-xl ml-1' />
            Repair Orders
          </DefaultNavLink>
          <DefaultNavLink pathName='/maintenance-reports'>
            <i className='fas fa-clipboard fa-fw mr-3 text-xl ml-1' />
            Maintenance Reports
          </DefaultNavLink>
          <DefaultNavLink pathName='/contacts'>
            <i className='fas fa-address-book fa-fw mr-3 text-xl ml-1' />
            Contacts List
          </DefaultNavLink>
          <DefaultNavLink pathName='/claims-reporting'>
            <i className='fas fa-car-crash fa-fw mr-3 text-xl ml-1' />
            Claims Reporting
          </DefaultNavLink>
          {driver != null && driver.type === 'owner' && (
            <DefaultNavLink pathName='/settlements'>
              <i className='fas fa-coins fa-fw mr-3 text-xl ml-1' />
              Settlements
            </DefaultNavLink>
          )}
          {driver != null && featureFlags[MOVE_DRIVER_COMPLIANCE] && (
            <DefaultNavLink pathName='/compliance'>
              <i className='fas fa-calendar-check  fa-fw mr-3 text-xl ml-1' />
              My Compliance
            </DefaultNavLink>
          )}
          <DefaultNavLink pathName='/help'>
            <i className='fas fa-question-square fa-fw mr-3 text-xl ml-1' />
            Help Page
          </DefaultNavLink>
        </nav>
        {driver && (
          <>
            <div className='flex items-center text-sm bg-primary-120 text-text-20 p-4'>
              <div className='mr-2'><i className='fas fa-gas-pump text-2xl' /></div>
              <div>
                <div>This week&apos;s fuel</div>
                {(featureFlags[MOVE_FUEL_RATES_V2] && driver?.fuelRates) && Object.keys(driver.fuelRates)
                  .map((fuel) => <div key={`rate-${fuel}`}>{`${fuel} ${driver.fuelRates[fuel]}%`}</div>)}
                {(!featureFlags[MOVE_FUEL_RATES_V2] && driver) && (
                  driver.showOtrFuel
                    ? <div>{`LOCAL ${driver.weeklyFuelLocalPercent}% | OTR ${driver.weeklyFuelOtrPercent}%`}</div>
                    : <div>{`LOCAL ${driver.weeklyFuelLocalPercent}%`}</div>
                )}
              </div>
            </div>
            <div className='flex items-center bg-primary-100 text-white text-sm p-4'>
              <Link
                to='/profile'
                onClick={handleCollapse}
                className='flex flex-1 mr-2'
              >
                {driverPhoto && (
                  <img
                    className='object-cover rounded-full bg-gray-50 mr-3'
                    style={{
                      width: '30px',
                      height: '30px',
                    }}
                    src={driverPhoto}
                    alt='user'
                  />
                )}
                <div className='flex-1 flex flex-col'>
                  <div>
                    {`${capitalize(driver?.firstName?.toLowerCase?.())} ${capitalize(driver?.lastName?.toLowerCase?.())}`}
                  </div>
                  <div>
                    {`# ${driver.loginId}`}
                  </div>
                </div>
              </Link>
              <LogoutButton logOut={logOut} />
            </div>
          </>
        )}
      </div>
    </Menu>
  );
};

export default SideMenu;
